import React, { FC, useContext, useMemo, useState } from 'react';

import Image from 'next/image';

import { Button, Tooltip } from 'antd';
import cn from 'classnames';

import { createApplicationPayment } from '@/api/create-application-payment';
import GoldFishIcon from '@/assets/gold-fish-icon.svg';
import IncludedIcon from '@/assets/included-icon.svg';
import PromptIcon from '@/assets/prompt-icon.svg';
import UnincludedIcon from '@/assets/unincluded-icon.svg';
import { ApplicationPaymentType } from '@/constants/application-payments';
import { FeedbackContext } from '@/context/feeadback.context';
import { useTranslate } from '@/hooks/use-translate';
import { Tariff } from '@/types/tariff';

import { en } from '../en';
import { InvoiceModal } from '../InvoiceModal/InvoiceModal';
import { InvoiceFieldType } from '../InvoiceModalContent/InvoiceModalContent';
import { TariffsTableProps } from '../types';
import { getTariffsInitialState } from '../utils';

import styles from './TariffsTableDesktop.module.scss';

type OwnProps = TariffsTableProps & { tariffsInfoData: Tariff.TariffsInfoResponse };

export const TariffsTableDesktop: FC<OwnProps> = ({
	tariffsInfoData,
	showButtons,
	userIsAuth,
	onTariffChoice,
	tariffChoiceDisable,
	isActiveTariff,
	currentTariff,
	currentPerYear,
}) => {
	const [isShowMore, setIsShowMore] = useState(true);
	const [isOpenModal, setIsOpenModal] = useState(false);
	const [slicedItems, setSlicedItems] = useState(getTariffsInitialState(tariffsInfoData));
	const [isLoading, setIsLoading] = useState(false);
	const { notify } = useContext(FeedbackContext);

	const tableRef = React.useRef<HTMLDivElement>(null);

	const t = useTranslate(en);

	const vipTariff = useMemo(() => {
		return tariffsInfoData.tariffs.find((tariff) => tariff.applyOnRequest);
	}, [tariffsInfoData]);

	const showModal = () => {
		setIsOpenModal(true);
	};

	const hideModal = () => {
		setIsOpenModal(false);
	};

	const handleClickVipTariff = () => {
		showModal();
	};

	const handleSendInvoice = async (formData: InvoiceFieldType) => {
		const { name, phone, email, comment } = formData;

		try {
			setIsLoading(true);

			await createApplicationPayment({
				type: ApplicationPaymentType.Tariff,
				infoForRequest: {
					name,
					phone,
					email,
					comment,
				},
				tariff: vipTariff.id,
			});

			notify.success({
				message: t('Ваша заявка успешно отправлена'),
			});
			hideModal();
		} catch (error) {
			notify.error({
				message: error?.message ?? t('Произошла ошибка при отправке заявки'),
				description: t('Пожалуйста, повторите запрос'),
			});
		} finally {
			setIsLoading(false);
		}
	};

	const getButton = (tariff: Tariff.TariffWithSettings, text: string) => {
		if (tariff.name === Tariff.TariffName.Basic) {
			return (
				<Button
					className={styles.choiceButton}
					type="primary"
					size="large"
					onClick={() => onTariffChoice({ tariffInfo: tariff, per_three_month: true })}
					disabled={tariffChoiceDisable}
				>
					{text}
				</Button>
			);
		}
		if (tariff.name === Tariff.TariffName.Silver) {
			return (
				<Button
					className={styles.choiceButton}
					type="primary"
					size="large"
					onClick={() => onTariffChoice({ tariffInfo: tariff, per_three_month: true })}
					disabled={tariffChoiceDisable}
				>
					{text}
				</Button>
			);
		}
		if (tariff.name === Tariff.TariffName.Gold) {
			return (
				<Button
					className={styles.choiceButton}
					type="primary"
					size="large"
					onClick={() => onTariffChoice({ tariffInfo: tariff, perYear: true })}
					disabled={tariffChoiceDisable}
				>
					{text}
				</Button>
			);
		}
		if (tariff.name === Tariff.TariffName.PlatinumDiamond) {
			return (
				<Button
					className={styles.choiceButton}
					type="primary"
					size="large"
					onClick={handleClickVipTariff}
					disabled={tariffChoiceDisable}
				>
					{text}
				</Button>
			);
		}
	};

	const renderButtons = (tariff: Tariff.TariffWithSettings) => {
		const tariffButtonText =
			tariff.name === Tariff.TariffName.PlatinumDiamond ? 'Запросить' : 'Выбрать';

		if (!showButtons) return null;

		if (!userIsAuth) {
			return getButton(tariff, t(tariffButtonText));
		}

		if ((currentTariff && !isActiveTariff) || !currentTariff) {
			return getButton(tariff, t(tariffButtonText));
		}

		if (currentTariff === tariff.id && currentPerYear === true) {
			return <p className={styles.currentTariff}>{t('Ваш текущий тариф')}</p>;
		}

		return getButton(tariff, t('Сменить'));
	};

	const renderParameterCell = (setting: Tariff.Setting, isFirstItem: boolean) => {
		if (typeof setting.value === 'string') {
			return setting.value === 'без комиссии' || setting.value === 'without commission' ? (
				<div className={styles.withoutCommission}>{setting.value}</div>
			) : (
				<>
					<div
						className={cn(styles.settingValue, {
							[styles.boldSettingValue]: isFirstItem,
						})}
					>
						{setting.value}
					</div>
					{setting.description && (
						<div className={styles.settingDescription}>{setting.description}</div>
					)}
				</>
			);
		}

		return setting.value ? (
			<Image width={24} height={24} alt="included" src={IncludedIcon} />
		) : (
			<Image width={24} height={24} alt="unincluded" src={UnincludedIcon} />
		);
	};

	const handleClickShowMore = () => {
		if (isShowMore) {
			setSlicedItems(tariffsInfoData.tariffsInfo);
		} else {
			setSlicedItems(getTariffsInitialState(tariffsInfoData));
			tableRef.current?.scrollIntoView({ block: 'start' });
		}

		setIsShowMore(!isShowMore);
	};

	return (
		<div className={styles.table} ref={tableRef}>
			<div className={styles.header}>
				<div className={styles.headerCell} key="head-cell">
					<h2 className={styles.title}>{t('Тарифы')}</h2>
					<div className={styles.tariffDate}>{t('с 1 Апреля 2024 года')}</div>
				</div>
				{tariffsInfoData.tariffs.map((tariff) => {
					return (
						<div
							key={tariff.id}
							className={cn(styles.tariffCell, {
								[styles.bestChoiceColumn]: tariff.bestChoice,
							})}
						>
							<div className={styles.tariffName}>
								{tariff.bestChoice && (
									<div className={styles.bestChoiceLabel}>
										{t('Лучший выбор!')}
									</div>
								)}
								{tariff.name}
								{tariff.name === Tariff.TariffName.PlatinumDiamond && (
									<Image
										className={styles.goldFishIcon}
										width={24}
										height={24}
										alt="gold-fish-icon"
										src={GoldFishIcon}
									/>
								)}
							</div>
							<div className={styles.tariffDescription}>{tariff.prompt}</div>
						</div>
					);
				})}
			</div>
			<div className={styles.body}>
				<div className={styles.settingsColumn}>
					{slicedItems.map((tariffInfo) => (
						<div key={tariffInfo.id} className={styles.labelCell}>
							<div>{tariffInfo.name}</div>
							{tariffInfo.prompt && (
								<Tooltip
									color="white"
									overlayClassName={styles.tooltipContainer}
									overlayInnerStyle={{ color: '#0a2653', padding: '1.4rem' }}
									title={tariffInfo.prompt}
								>
									<Image
										className={styles.hint}
										src={PromptIcon}
										alt="prompt-icon"
									/>
								</Tooltip>
							)}
						</div>
					))}
				</div>
				{tariffsInfoData.tariffs.map((tariff) => {
					return (
						<div
							key={tariff.name}
							className={cn(styles.infoColumns, {
								[styles.bestChoiceColumn]: tariff.bestChoice,
							})}
						>
							{slicedItems.map((tariffInfo, index) => {
								const isFirstItem = index === 0;
								const tariffSetting = tariff.settings.find(
									(item) => item.infoId === tariffInfo.id,
								);

								return (
									<div
										key={tariffSetting.description + tariffSetting.infoId}
										className={styles.labelSetting}
									>
										{renderParameterCell(tariffSetting, isFirstItem)}
									</div>
								);
							})}
						</div>
					);
				})}
				<div className={styles.showMoreButtonWrapper}>
					<Button
						type="text"
						className={styles.showMoreButton}
						size="large"
						onClick={handleClickShowMore}
					>
						{t(isShowMore ? 'Показать все преимущества' : 'Скрыть преимущества')}
					</Button>
				</div>
				{tariffsInfoData?.tariffs?.map((tariff) => {
					return (
						<div
							key={tariff.name}
							className={cn(styles.infoColumns, {
								[styles.bestChoiceColumn]: tariff.bestChoice,
								[styles.buttonWrapper]: !tariff.bestChoice,
							})}
						>
							{renderButtons(tariff)}
						</div>
					);
				})}
			</div>
			<div className={styles.infoDescription}>
				{t(
					'С подробными условиями тарификации можно ознакомиться, связавшись с нами по телефону +7 908 999 80 80.',
				)}
			</div>
			{isOpenModal && (
				<InvoiceModal
					isLoading={isLoading}
					onSendInvoice={handleSendInvoice}
					isOpenModal={isOpenModal}
					title={t('Запрос VIP-тарифа')}
					onHideModal={hideModal}
					description="Менеджер Fishplace свяжется с вами — расскажет об условиях тарифа, преимуществах платформы и поможет с регистрацией."
				/>
			)}
		</div>
	);
};
